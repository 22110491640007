<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/customer`"
                title="Customer"
                :columns="columns"
                routerpath="/crm/customer/addcustomer"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              >
                <template #action="{ row, getGridData }">
                  <approve-reject
                    title="Customer"
                    :row="row"
                    :getGridData="getGridData"
                    :approve="true"
                    :reject="true"
                    endpoint="customer"
                  />
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import moment from "moment";
import approveReject from "@/components/approveReject.vue";

export default {
  components: {
    GoodTableColumnSearch,
    approveReject,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "Image",
          field: "profile_image",
          type: "image",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Name",
          field: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Lead Source",
          field: "leadsource",
          responseValue: "name",
          type: "dropdown",
          url: "leadsource?pagenum=1&pagesize=5000",
          filterOptions: {
            enabled: true,
            placeholder: "Search Lead Source",
          },
        },
        {
          label: "Sub Source",
          field: "subsource",
          responseValue: "name",
          type: "dropdown",
          url: "leadsource?pagenum=1&pagesize=5000",
          filterOptions: {
            enabled: true,
            placeholder: "Search Sub Source",
          },
        },

        {
          label: "Lead Date",
          field: "leaddate",
          filterOptions: {
            enabled: true,
            placeholder: "Search Lead Date",
          },
        },
        {
          label: "Mobile",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
        },

        {
          label: "Status",
          field: "approved1",
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "Assign to",
          field: "assignto",
          responseValue: "fullname",
          type: "staticdropdown",
          url: "getUsers",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          responseValue: "name",
          type: "staticdropdown",
          url: "getUsers",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },

        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          responseValue: "name",
          type: "staticdropdown",
          url: "getUsers",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      viewFields: [
        {
          label: "Profile",
          field: "profile_image",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Lead Source",
          field: "leadsource",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Sub Source",
          field: "subsource",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Title",
          field: "title",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Client's Name",
          field: "name",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Client's Surname",
          field: "surname",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Client's Father/Husband Name",
          field: "fathername",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Client's Occupation",
          field: "occupation",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Birth Date",
          field: "birthdate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Lead Date",
          field: "leaddate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Mobile",
          field: "mobile",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Email",
          field: "email",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Gender",
          field: "gender",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Marital Status",
          field: "maritalstatus",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Nominee",
          field: "nomineename",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Nominee Relationship",
          field: "nomineerelation",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Nominee Phone",
          field: "nomineephone",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Nominee DOB",
          field: "nomineedob",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Current Address",
          field: "heading",
          type: "heading",
          class: "col-md-12",
        },
        {
          label: "Address Line 1",
          field: "curaddressline1",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Address Line 2",
          field: "curaddressline2",
          type: "text",
          class: "col-md-4",
        },

        {
          label: "Pin Code",
          field: "curpincode",
          type: "text",
          class: "col-md-4",
        },

        {
          label: "City",
          field: "curcity",
          type: "text",
          class: "col-md-4",
        },

        {
          label: "Area",
          field: "curarea",
          type: "text",
          class: "col-md-4",
        },

        {
          label: "Taluka",
          field: "curtaluka",
          type: "text",
          class: "col-md-4",
        },

        {
          label: "State",
          field: "curstate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Parmanent Address",
          field: "heading",
          type: "heading",
          class: "col-md-12",
        },
        {
          label: "Address Line 1",
          field: "peraddressline1",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Address Line 2",
          field: "peraddressline2",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Pin Code",
          field: "pincode",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "City",
          field: "percity",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Area",
          field: "perarea",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Taluka",
          field: "pertaluka",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "State",
          field: "perstate",
          type: "text",
          class: "col-md-4",
        },

        {
          label: "Pan No",
          field: "panno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Pan Card",
          field: "pancard",
          type: "attachment",
          class: "col-md-4",
        },

        {
          label: "Aadhar No",
          field: "aadharcardno",
          type: "text",
          class: "col-md-4",
        },

        {
          label: "Aadhar Card Front Side",
          field: "aadharfrontside",
          type: "attachment",
          class: "col-md-4",
        },

        {
          label: "Aadhar Card Back Side",
          field: "aadharbackside",
          type: "attchment",
          class: "col-md-4",
        },

        {
          label: "VoterId No",
          field: "voterid",
          type: "text",
          class: "col-md-4",
        },

        {
          label: "Voter Id",
          field: "voteridcard",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Remark",
          field: "remarks",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Select Type",
          field: "",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Assign TO",
          field: "",
          type: "text",
          class: "col-md-4",
        },
      ],
      formOptions: {
        gridForm: true,
        formTitle: `${this.$route.params.id ? "Edit Customer" : "Add Customer"}`,
        submitRouterPath: "/crm/customer",
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/getusersbyid`,
        url: `${baseApi}/customer`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Customer Name",
            name: "customername",
            value: "",
            type: "text",
            placeholder: "Enter Customer Name",
            class: "col-md-6",
            // required: true,
          },
          {
            label: "Mobile",
            name: "mobile",
            value: "",
            type: "number",
            placeholder: "Enter Mobile",
            class: "col-md-6",
            // required: true,
          },
          {
            label: "Email",
            name: "email",
            value: "",
            type: "email",
            placeholder: "Enter Mobile",
            class: "col-md-6",
            // required: true,
          },
          {
            label: "Address 1",
            name: "address1",
            value: "",
            type: "text",
            placeholder: "Enter Address 1",
            class: "col-md-6",
            // required: true,
          },
          {
            label: "Address 2",
            name: "address2",
            value: "",
            type: "text",
            placeholder: "Enter Address 2",
            class: "col-md-6",
            // required: true,
          },
          {
            label: "City",
            name: "city",
            value: "",
            type: "text",
            placeholder: "Enter city",
            class: "col-md-4",
            // required: true,
          },
          {
            label: "Pin Code",
            name: "pincode",
            value: "",
            type: "text",
            placeholder: "Enter Pincode",
            class: "col-md-4",
            // required: true,
          },
          {
            label: "State",
            name: "state",
            value: "",
            type: "text",
            placeholder: "Enter state",
            class: "col-md-4",
            // required: true,
          },
          {
            label: "Country",
            name: "country",
            value: "",
            type: "text",
            placeholder: "Enter Country",
            class: "col-md-4",
            // required: true,
          },
          {
            label: "Contact Person",
            name: "contactperson",
            value: "",
            type: "text",
            placeholder: "Enter Contact Person",
            class: "col-md-6",
            // required: true,
          },
          {
            label: "Contact Number",
            name: "contactnumber",
            value: "",
            type: "text",
            placeholder: "Enter Contact Number",
            class: "col-md-4",
            // required: true,
          },
          {
            label: "Contact Person Email",
            name: "contactpersonemail",
            value: "",
            type: "text",
            placeholder: "Enter Contact Person Email",
            class: "col-md-4",
            // required: true,
          },
          {
            label: "Select Employee",
            name: "employee",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "employee",
            // required: true,
            options: [],
          },
          {
            label: "User Name",
            name: "username",
            value: "",
            type: "text",
            placeholder: "Enter User Name",
            class: "col-md-4",
            // required: true,
          },
          // {
          //   label: 'Password',
          //   name: 'password',
          //   value: '',
          //   type: 'password',
          //   placeholder: 'Enter Password',
          //   class: 'col-md-4'
          //   // required: true,
          // },
          {
            label: "Gender",
            name: "gender",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "gender",
            options: [{ name: "Male" }, { name: "Female" }],
            // required: true,
          },
          {
            label: "Photo",
            name: "photo",
            value: "",
            type: "fileinput",
            placeholder: "select Photo",
            class: "col-md-6",
            // required: true,
          },
        ],
      },
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Customer") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
